.App {
    text-align: center;
}

#spinner[role='alert'] > div:first-child span {
    animation: spinner-animation infinite 1.1s cubic-bezier(0.5, 0, 0.5, 1);
    pointer-events: none;
}

#spinner[data-name='block-loading-indicator'] {
    backdrop-filter: none;
    background-color: rgba(0, 0, 0, 0.1);
}

@keyframes spinner-animation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

div[class^='popupContainerStyle'] > div[role='dialog'] {
    position: relative;
    left: -2px;
}

div[id='expander'] {
    border-width: 1px;
    box-shadow: rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px, rgb(0 0 0 / 20%) 0px 2px 4px -1px;
}

div[data-name='content'] {
    z-index: unset;
}

div.text-area-scrollable textarea {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;
}

div.text-area-hide-scrollbar textarea::-webkit-scrollbar {
    display: none;
}

.hide-navbar-item {
    display: none;
}
