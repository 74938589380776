body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.custom-row > .ag-cell {
    display: flex;
    align-items: center;
    user-select: text;
    cursor: text;
}

.custom-row.pointer-cursor > .ag-cell {
    cursor: pointer;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ag-theme-alpine-dark .ag-overlay-loading-wrapper {
    background-color: rgba(24, 29, 31, 0.66) !important;
}
